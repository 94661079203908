exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-story-installment-story-installment-js": () => import("./../../../src/templates/story-installment/story-installment.js" /* webpackChunkName: "component---src-templates-story-installment-story-installment-js" */),
  "component---src-templates-story-with-installments-story-with-installments-js": () => import("./../../../src/templates/story-with-installments/story-with-installments.js" /* webpackChunkName: "component---src-templates-story-with-installments-story-with-installments-js" */),
  "component---src-templates-story-with-single-installment-story-with-single-installment-js": () => import("./../../../src/templates/story-with-single-installment/story-with-single-installment.js" /* webpackChunkName: "component---src-templates-story-with-single-installment-story-with-single-installment-js" */)
}

